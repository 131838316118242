<template>
    <div>
        <Dialog v-if='approvalVisible' :create="true" @on-close="closeIDsPopup" title="已审核" :left="140" :top="105"
            :width="1748">
            <!--待审批 -->
            <div class="Box">

                <!-- 赛选栏 -->
                <div class="select">
                    <el-select v-model="edit_form.nomAndUnit" size="mini" clearable placeholder="请选择用户类型"
                        class="button">
                        <el-option v-for="item in drd.cates2" :key="item.id" :label="item.title" :value="item.id" />
                    </el-select>
                    <el-select v-model="edit_form.areaBelongs" size="mini" clearable placeholder="请选择所属辖区"
                        class="button">
                        <el-option v-for="item in drd.prefectureList" :key="item.id" :label="item.title"
                            :value="item.title" />
                    </el-select>
                    <el-input v-model="edit_form.flightArea" class="w170 ml10 dib mr10 " size="mini"
                        placeholder="请输入飞行区域" clearable>
                    </el-input>
                    <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" class="" v-model="edit_form.startTime"
                        type="datetime" placeholder="选择开始时间">
                    </el-date-picker>
                    -
                    <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" class="ml5 mr10" v-model="edit_form.endTime"
                        type="datetime" placeholder="选择结束时间">
                    </el-date-picker>
                    <el-select v-model="edit_form.messageStatus" size="mini" style="width:160px" clearable placeholder="请选择证审批状态"
                        class="button">
                        <el-option v-for="item in drd.appList" :key="item.id" :label="item.title" :value="item.id" />
                    </el-select>

                    <div class="fr">
                        <el-input v-model="edit_form.userName" class="w170 ml10 dib mr10 " size="mini"
                            placeholder="请输入申请人姓名" clearable>
                        </el-input>
                        <div class="btn" @click="search">搜索</div>
                        <div class="btn" @click="reset">重置</div>
                        <JsonExcel class="dib" :data="dataSource" :fields="json_fields" name="报飞备案已审核统计.xls">
                        <div class="btn">导出</div>
                        
                    </JsonExcel>
                    <el-upload method="post" :auto-upload="true" :headers="headers" :show-file-list="false"
                                accept=".xlsx, .xls" :multiple="false" :http-request="(param) => airUpload(param)"
                                 action="#" class="upload-demo dib">
                                <!-- <el-button size="small" type="primary">点击上传</el-button> -->
                                <div class="btn dib">导入</div>
                            </el-upload>
                    </div>

                </div>
                <!-- 表格 -->
                <Table class="task-table" :columns="columns" :dataSource="dataSource" :pagination="pagination"
                    @size-change="
                    (size) => { pagination.size = size; async_get_list(); }"
                    @page-change="(page) => { async_get_list(page); }">
                    <template #index="{ row }">
                        <div class="action">
                            {{ row.index }}
                        </div>
                    </template>
                    <template #applyUserType="{ row }">
                        <div class="action">
                            {{ row.applyUserType == 1 ? '个人' : '单位' }}
                        </div>
                    </template>
                    <template #auditStatus="{ row }">
                        <div class="action">
                            <div v-if="row.auditStatus == 1" style="color:#b1a743">
                                待审核
                            </div>
                            <div v-if="row.auditStatus == 2" style="color:#65f062">
                                审核通过
                            </div>
                            <div v-if="row.auditStatus == 3" style="color:#aa2b36">
                                审核不通过
                            </div>
                        </div>
                    </template>
                    <template #action="{ row }">
                        <div class="action">
                            <div @click="approval(row)">
                                <el-tooltip content="详情" placement="top" effect="light">
                                    <div class="cp iconfont f4f7 f20 icon-chakanxiangqing"></div>
                                </el-tooltip>
                            </div>

                        </div>
                    </template>
                </Table>
            </div>
        </Dialog>

        <Dialog v-else :create="true" @on-close="back" title="详情" :left="140" :top="105" :width="1000">
            <div class="djInfoBox cf wh100">
                <div class="wh100" v-if="approvalList != null">
                    <div class="item">
                        <div>姓名:<span>{{ approvalList.applyUserName }}</span></div>
                        <div>申请人电话:<span>{{ approvalList.applyUserPhone }}</span></div>
                        <div>证件类型:<span>身份证</span></div>
                        <div>证件号码:<span>{{ approvalList.applyUserIdNo }}</span></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="mb20 f16"><span class="dec dib mr10"></span>飞行计划</div>
                    <div class="item">
                        <div>飞行目的:<span>{{ approvalList.flightGoal }}</span></div>
                        <div></div>
                        <div></div>
                        <div class=" ">
                            <div class="dib w120"> 飞行活动说明:</div>
                            <div>
                                <el-input type="textarea" :rows="2" v-model="approvalList.flightActivityRemark"
                                    disabled>
                                </el-input>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div>装载物品:<span>{{ approvalList.loadTheGoods }}</span></div>
                        <div>飞行范围:<span>{{ approvalList.flightScope }}</span></div>
                        <div>飞行半径(米):<span>{{ approvalList.flightRadius }}</span></div>
                        <div>飞行高度(米):<span>{{ approvalList.flightAltitude }}</span></div>
                        <div>飞行区域:<span>{{ approvalList.flightArea }}</span></div>
                        <!-- <div>航线规划(米):<span style="color:#49b457">查看航线</span></div> -->
                        <div>所属辖区:<span>{{prefecture[approvalList.jurisdiction]  }}</span></div>
                        <div>开始时间:<span>{{ approvalList.flightStartTime }}</span></div>
                        <div>结束时间:<span>{{ approvalList.flightEndTime }}</span></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="mb20 f16"><span class="dec dib mr10"></span>飞行设备</div>
                    <div class="item">
                        <div>产品名称:<span>{{ approvalList.productName }}</span></div>
                        <div>飞控序列号：<span>{{ approvalList.flightControlNumber }}</span></div>
                        <div>飞控账号:<span>{{ approvalList.flightControlAccount }}</span></div>
                        <div>空机重量：<span> {{ approvalList.deviceWeight }}kg</span></div>

                    </div>
                    <div class="mb20 f16"><span class="dec dib mr10"></span>附件</div>
                    <div class="item">
                        <div>飞行活动情况说明:<span class="btn w60" @click="showImg(approvalList.accessory)">查看说明</span></div>
                    </div>
                    <div class="mb20 f16"><span class="dec dib mr10"></span> 审批记录</div>
                    <div class="item">
                        <div>审批时间:<span>{{ approvalList.auditTime }}</span></div>
                        <div>审批结果:<span v-if="approvalList.auditStatus == 2" style="color:green">审核通过</span>
                            <span v-if="approvalList.auditStatus == 3" style="color:red">审核不通过</span>
                        </div>
                        <div>审批人:<span>{{ approvalList.auditUserName }}</span></div>
                        <div>审批单位:<span>{{ approvalList.auditUnit }}</span></div>
                        <div></div>
                        <div></div>

                        <div class=" ">
                            <div class=" w60 vat"> 审批备注:
                                <el-input style="vertical-align: top;" type="textarea" class="w800 " :rows="2" disabled
                                    v-model="approvalList.auditRemark">
                                </el-input>

                            </div>

                        </div>
                    </div>
                    <div class="mt20 tc wih00" style="margin:0 auto">
                        <div class=" btn" @click="back">返回</div>
                    </div>

                </div>

            </div>
        </Dialog>
        <ImgDialog title="预览图片" :imgFlag="false" :imgList='imgList' :imgListSrc="imgListSrc" v-if="ImgDialogFlag"
            @imgDialogClose="ImgDialogFlag = false"></ImgDialog>

    </div>
</template>
<script>
import Dialog from "@/pages/civilAircraft/components/dialog"
import Table from "@/components/common/table-new-hwb"; // 列表组件
import API from "@/api";
import ImgDialog from "@/pages/civilAircraft/components/imgDialog"
import JsonExcel from 'vue-json-excel'
export default {
    components: { Dialog, Table, ImgDialog, JsonExcel },

    data() {
        return {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            ImgDialogFlag: false,
            imgList: [],
            imgListSrc: "",
            textarea: "",
            approvalVisible: true,
            dataSource: [],
            prefecture: {
                1: '许村镇',
                2: '长安镇',
                3: '周王庙镇',
                4: '盐官镇',
                5: '丁桥镇',
                6: '斜桥镇',
                7: '袁花镇',
                8: '黄湾镇',
                9: '硖石街道',
                10: '海洲街道',
                11: '海昌街道',
                12: '马桥街道',
            },

            json_fields: {
                "序号": "index",    //常规字段
                "申报日期": "flightTime",
                "姓名": "applyUserName",
                "申请人类型(1个人/2单位)": "applyUserType",
                "无人机名称": "productName",
                "飞行目的": "flightGoal",
                "飞行活动说明": "flightActivityRemark",
                "飞行区域": "flightArea",
                "飞行日期": "flightTime",
                "审批结果": "auditStatus",
            },
            columns: [
                { prop: "index", label: "序号", width: "100px" },
                { prop: "flightTime", label: "申报时间", width: "230px" },
                { prop: "applyUserName", label: "姓名", width: "170px" },
                { prop: "applyUserType", slot: true, label: "申请人类型", width: "170px" },
                { prop: "productName", label: "无人机名称", width: "170px" },
                { prop: "flightGoal", label: "飞行目的", width: "170px" },
                { prop: "flightActivityRemark", label: "飞行活动说明", width: "170px" },
                { prop: "flightArea", label: "飞行区域", width: "170px" },
                { prop: "flightTime", label: "飞行日期", width: "170px" },
                { prop: "auditStatus", slot: true, label: "审批结果", width: "170px" },
                { prop: "action", slot: true, label: "操作", width: "170px" },
            ],
            pagination: {
                page: 1,
                pageSize: 10,
                total: 0,
            },
            edit_form: {  // 发起需求表单字段
                startTime: null,
                type: 2, // 待审批
                userName: null,
                size: 10,
                page: 1,
                endTime:null,
                nomAndUnit: null,
                flightArea: null,
                areaBelongs: null,//所属辖区
                messageStatus:null,//审批状态
            },
            selectList: null, // 赛选的机构
            drd: {
                cates2: [
                    { id: 1, title: "个人" },
                    { id: 2, title: "单位" },
                ],
                cates: [
                    { id: 2, title: "常态任务" },
                    { id: 3, title: "临时紧急任务" },
                ],
                scenes: null,
                uav_type: [],
                mount_type: [],
                task_type: [],
                task_cate: [],
                flightLine: [],
                proStatus: [
                    { id: 1, label: "待处理", value: 0 },
                    { id: 2, label: "处理完成", value: 1 },
                    { id: 3, label: "无需处理", value: 2 },
                ],
                prefectureList: [
                    { id: 1, title: '许村镇' },
                    { id: 2, title: '长安镇' },
                    { id: 3, title: '周王庙镇' },
                    { id: 4, title: '盐官镇' },
                    { id: 5, title: '丁桥镇' },
                    { id: 6, title: '斜桥镇' },
                    { id: 7, title: '袁花镇' },
                    { id: 8, title: '黄湾镇' },
                    { id: 9, title: '硖石街道' },
                    { id: 10, title: '海洲街道' },
                    { id: 11, title: '海昌街道' },
                    { id: 12, title: '马桥街道' },
                ],
                appList:[
                    {id:1,title:'待审核'},
                    {id:2,title:'审核通过'},
                    {id:3,title:'审核不通过'},
                ]
            },
            approvalList: null
        }
    }, created() {
        this.async_get_list()
    },
    methods: {
        async airUpload(file) {
            let res = null;
            let formData = new FormData();
            formData.append("file", file.file);
            try {
                res = await API.AIRCRAFT.flightImport(formData);
                if (res.code === 200) {
                    this.$message.success("数据添加成功!");
                    this.pagination.page = 1
                    this.async_get_list()
                } else {
                    this.$message.error("数据添加失败,请按照模板上传文件!");
                }
            } catch (error) {
                console.log(error);
            }
        },
        search() {
            this.pagination.page = 1
            this.async_get_list()
        },
        showImg(urlList) {
            if (urlList.length > 0) {
                let arr = JSON.parse(urlList)
                this.imgList = arr.map(item => item.url)
                this.imgListSrc = this.imgList[0]
                this.ImgDialogFlag = true
            } else {
                this.$message.warning('暂无附件')
            }

        },
        back() {
            this.approvalList = null
            this.textarea = null;
            this.approvalVisible = true
            this.$forceUpdate()
        },
        // 审批
        approval(approval) {
            if (approval) {
                let data = this.dataSource.filter(item => item.id == approval.id)
                this.approvalList = JSON.parse(JSON.stringify(data[0]))
            }
            this.approvalVisible = false
        },
        reset() {
            this.edit_form= {  // 发起需求表单字段
                startTime: null,
                type: 2, // 待审批
                userName: null,
                size: 10,
                page: 1,
                endTime:null,
                nomAndUnit: null,
                flightArea: null
            }
            this.search()
        },

        getColor(color) {
            return `color: ${color}`
        },
        closeIDsPopup() {
            this.$emit('closeDemand');
        },
        async async_get_list(page) {
            if (page) {
                this.pagination.page = page
            }
            for (const key in this.pagination) {
                if (this.edit_form[key]) {
                    this.edit_form[key] = this.pagination[key]
                }
            }
            let res = await API.AIRCRAFT.flightPage(this.edit_form)
            if (res.code == 200) {
                this.dataSource = res.data.map((item, index) => {
                    return ({
                        ...item,
                        index: index + 1
                    })
                })
                this.pagination.total = res.total
            }

        }
    },
}
</script>
<style lang="scss" scoped>
.f4f7 {
    color: #77f4f7
}

.Box {
    min-height: 50vh;

    .tableSelect {
        display: flex;
        justify-content: flex-start;

        .item {
            cursor: pointer;
            display: flex;
            text-align: center;
            justify-content: space-evenly;
            align-items: center;
        }

        .dsp {
            background-image: url(~@/assets/images/civilAircraft/1.png);

            &.selected {
                background-image: url(~@/assets/img/click-3.png);
            }
        }

        .sptg {
            background-image: url(~@/assets/images/civilAircraft/2.png);

            &.selected {
                background-image: url(~@/assets/img/click-1.png);
            }
        }
    }

    .select {
        margin: 32px 0 24px 0;

        ::v-deep {
            .el-input__inner {
                background-color: #000;
                border: 0;
                height: 30px;
                line-height: 30px;
                color: #fff;
                font-size: 14px;
            }
        }

        .button {
            margin-right: 10px;
            width: 210px;
        }

        .search-button {
            display: inline-block;
            width: 54px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin-left: 10px;
            cursor: pointer;
            border-radius: 4px;
            background-color: #000;
            color: #fff;
            text-align: center;
            box-sizing: border-box;
        }

        .btn {
            padding: 5px 10px;
            display: inline-block;
            background: rgba(38, 71, 238, 0.71);
            box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
            border-radius: 2px;
            border: 1px solid;
            // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            margin-right: 10px;
            cursor: pointer;
        }

        .btn:hover {
            background: rgba(38, 71, 238, 0.91);

        }
    }

    .task-table {
        min-height: 50vh;
    }
}

.djInfoBox {
    display: flex;

    .dec {
        width: 4px;
        height: 17px;
        background: #FFC300;
        border-radius: 2px;
    }

    .item {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;

        div {
            min-width: 30%;
            margin-bottom: 20px;
            flex: 1;
            color: #8799b7;
            white-space: nowrap;

            span {
                display: inline-block;
                overflow: hidden;
                vertical-align: bottom;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 200px;
                margin-left: 20px;
                color: #effaff;
            }
        }
    }

}

.btn {
    padding: 5px 10px;
    display: inline-block;
    background: rgba(38, 71, 238, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 2px;
    border: 1px solid;
    // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    margin-right: 10px;
    cursor: pointer;
}

.btn:hover {
    background: rgba(38, 71, 238, 0.91);

}

::v-deep .el-input__icon {
    line-height: 30px;
}

::v-deep .el-textarea__inner {
    display: inline-block;
    box-sizing: border-box;
    background: #020b1d !important;
    background: transparent;
    border: 1px solid rgba(12, 34, 67, 1) !important;
    border-radius: 0;
    font-size: 16px;
    color: #9fc4ff;
    letter-spacing: 0;
}
</style>