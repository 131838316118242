<template>
    <el-dialog  :append-to-body="true" :title="title" :visible.sync="dialogVisible" width="60%" :before-close="handleClose">

        <div class="wih100 box h500">
            <img v-if="imgFlag" :src="src" class="dib  ht200 "  alt="">
            <el-image v-else class="dib wih100 " style="height:400px"  :src="src"
                            :preview-src-list="imgList"> </el-image>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        title: { // 标题
            type: String,
            default: '请传入一个标题放在这里'
        },
        src: {
            type: String,
            default: '2131'
        },
        imgFlag:{
            type:Boolean,
            default:true
        },
        imgList: {
            type: Array,
            default: ()=>[]
        },
        
    },
    data() {
        return {
            dialogVisible: true
        }
    },
    methods: {
        handleClose(){
            this.$emit('imgDialogClose')

        },
    }
}
</script>

<style lang="scss" scoped>
.box{
    overflow-y: auto;
}
.hwb-dialog {
    right: unset;
    bottom: unset;
    color: #fff;

    ::v-deep {
        .el-dialog {
            border: 0px !important;
            background: transparent;
            position: relative;

            .el-dialog__header {
                width: 100%;
                height: 34px;
                padding: 0;
                box-sizing: border-box;
                background: rgba(21, 95, 192, 0.3);
                box-shadow: inset 0px 0px 10px 0px #00142F;
                border: 1px solid #73A6D1;
                backdrop-filter: blur(2px);

                .el-dialog__title {
                    box-sizing: border-box;
                    padding-left: 47px;
                    font-family: YouSheBiaoTiHei;
                    display: block;
                    width: 100%;
                    height: 34px;
                    line-height: 32px;
                    font-size: 22px;
                    font-weight: bold;
                    text-align: left;
                    backdrop-filter: blur(2px);
                    background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 36%, #54ACFF 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .el-dialog__headerbtn {
                    display: none;
                }
            }

            .guanbi {
                position: absolute;
                top: -30px;
                right: 16px;
                font-size: 22px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                cursor: pointer;
            }

            .jiantou {
                position: absolute;
                top: -27px;
                left: 8px;
                font-size: 22px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
            }

            .chuangjian {
                position: absolute;
                top: -30px;
                right: 100px;
                width: 86px;
                height: 26px;
                background: rgba(38, 71, 238, 0.71);
                box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
                border-radius: 2px;
                border: 1px solid;
                border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
                font-size: 14px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 26px;
                text-align: center;
                cursor: pointer;
            }

            .el-dialog__body {
                background: rgba(40, 53, 122, 0.1);
                border: 1px solid #73A6D1;
                box-shadow: 0px 0px 5px #73A6D1;
                border-top: 0;
                backdrop-filter: blur(2px);
            }
        }

    }
}
</style>