<template>
    <div class="deviceBox">
        <Menu @on-Click="showList" :iconList="list" />
        <div>
            <component @closeDemand="closeDemand" :is="type[showListType]"> </component>
        </div>
    </div>
</template>
<script >
import Menu from "@/components/newmenu/menu";
import Table1 from "./components/table1";
import Table2 from "./components/table2";
export default {
    components: { Menu, Table1, Table2 },
    data() {
        return {
            list:
                [
                    {
                        id: 1,
                        name: '备案审核',
                    },
                    {
                        id: 2,
                        name: '已审核备案',
                    },
                ],
            showListType: 1, // 控制展示左侧哪个导航
            type: {
                1: Table1,
                2: Table2,
            }
        }
    },
    created() {
        if (!window.viewer.scene.globe.show) {
            window.plate_data_source.show = false
            window.viewer.scene.globe.show = true
        }
    },
    methods: {
        closeDemand() {
            this.showListType = false
        },
        showList(item) {
            this.showListType = item.id
            this.$forceUpdate()
        },
    },
}

</script>
<style scoped  lang="scss">
.deviceBox {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 108px;
    height: 100%;
}
</style>